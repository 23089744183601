<template>
  <div>
    <headPage />
    <!-- 沉浸体验 -->
    <div class="box1" style="overflow: hidden">
      <div class="info">
        <p class="p1">让舒适理念落地生根</p>
        <p class="p2">让客户身临其境的沉浸式体验</p>
      </div>
    </div>
    <!-- 综合展厅 -->
    <div class="box2" style="overflow: hidden">
      <div class="info2">
        <div data-aos="fade-right">
          <p class="p3">MH享家</p>
          <p class="p4">综合展厅</p>
        </div>
        <p class="p5" data-aos="fade-left">
          集品牌介绍、样板工艺、实景体验于一体的综合展厅
        </p>
        <img
          class="img1"
          style="display: block"
          src="../assets/image/exhibitionHall/image8.png"
        />
      </div>
    </div>
    <!-- 实景展厅 -->
    <div class="box3" style="overflow: hidden">
      <img
        class="img2"
        style="display: block; width: 100%"
        src="../assets/image/exhibitionHall/image1.png"
      />
      <div class="info3">
        <div>
          <p class="p6">MH享家</p>
          <p class="p7">实景展厅</p>
        </div>
        <img
          class="img3"
          style="display: block"
          src="../assets/image/exhibitionHall/image4.jpeg"
        />
        <img
          class="img4"
          style="display: block"
          src="../assets/image/exhibitionHall/image5.jpeg"
        /><img
          class="img5"
          style="display: block"
          src="../assets/image/exhibitionHall/image6.jpeg"
        />
        <img
          class="img6"
          style="display: block"
          src="../assets/image/exhibitionHall/image7.jpeg"
        />
        <img
          class="img7"
          style="display: block"
          src="../assets/image/exhibitionHall/image9.png"
        />
        <!-- <video
          class="v1"
          src="../assets/image/exhibitionHall/hall.mp4"
          controls="controls"
          poster="../assets/image/exhibitionHall/image7.jpeg"
        ></video> -->
        <div class="about">
          <div v-if="videoUrl">
            <video
              class="v1"
              :src="videoUrl"
              controls="controls"
              id="upvideo"
              :poster="imgsrc"
            >
              您的浏览器不支持视频播放
            </video>
          </div>
          <canvas id="mycanvas" style="display: none"></canvas>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  data() {
    return {
      videoUrl: require("../assets/image/exhibitionHall/hall.mp4"),
      imgsrc: "",
    };
  },
  mounted() {
    this.findvideocover(this.videoUrl, { url: "" });
  },
  methods: {
    jump(url) {
      this.$router.push(url);
    },
    findvideocover(url, file) {
      const video = document.getElementById("upvideo"); // 获取视频对象
      // const video = document.createElement("video") // 也可以自己创建video
      video.src = url; // url地址 url跟 视频流是一样的
      var canvas = document.getElementById("mycanvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧
      video.oncanplay = () => {
        canvas.width = video.clientWidth; // 获取视频宽度
        canvas.height = video.clientHeight; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
        // 转换成base64形式
        this.imgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        file.url = this.imgsrc;
      };
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.img3:hover {
  transform: scale(1.2);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.img4:hover {
  transform: scale(1.4);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.img5:hover {
  transform: scale(1.4);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.img6:hover {
  transform: scale(1.2);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.img1 {
  width: 45%;
  margin-left: 418px;
  margin-top: 10px;
}
.info {
  margin-top: 230px;
}
.info2 {
  height: 567px;
  margin-top: 151px;
  position: relative;
  overflow: hidden;
}
.box1 {
  background-image: url(../assets/image/exhibitionHall/image3.png);
  background-size: cover;
  width: 100%;
  height: 632px;
}
.box2 {
  background-image: url(../assets/image/exhibitionHall/image2.png);
  background-size: cover;
  width: 100%;
  height: 733px;
}
.box3 {
  height: 1450px;
}
.p1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 63px;
  color: #d3b968;
}
.p2 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 65px;
  color: #fff;
  margin-top: -13px;
}
.p3 {
  font-family: "SourceHanSansCN-Medium";
  color: #d1a937;
  font-size: 58px;
  position: absolute;
  left: 193px;
  top: 10px;
}
.p4 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 52px;
  color: #fff;
  position: absolute;
  left: 192px;
  top: 94px;
}
.p5 {
  color: #d7d7d7;
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  position: absolute;
  text-align: left;
  width: 252px;
  right: 150px;
  top: 363px;
}
.p6 {
  color: #ba9f4e;
  font-family: "SourceHanSansCN-Medium";
  font-size: 52px;
  position: absolute;
  top: 57px;
  left: 37%;
}
.p7 {
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
  font-size: 52px;
  position: absolute;
  top: 57px;
  left: 49%;
}
.box3 {
  position: relative;
  background-color: #000;
}
.img3 {
  width: 36%;
  position: absolute;
  border-radius: 20px;
  top: 179px;
  left: 130px;
}
.img4 {
  top: 446px;
  left: 135px;
  width: 17%;
  position: absolute;
  border-radius: 20px;
  transform: rotateY(180deg);
}
.img5 {
  top: 446px;
  left: 420px;
  width: 17%;
  position: absolute;
  border-radius: 20px;
}
.img6 {
  width: 23%;
  position: absolute;
  border-radius: 20px;
  top: 179px;
  left: 705px;
}
.img7 {
  width: 17.5%;
  position: absolute;
  border-radius: 20px;
  top: 152px;
  left: 1108px;
}
.v1 {
  height: 719px;
  width: auto;
}
</style>